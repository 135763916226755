const service = {
    signin: "api/admin/admin_login",
    // gettotalprofit : "api/admin/get_month_profit",
    // gettodayprofit : "api/admin/get_today_profit",
    // getyesterdayprofit : "api/admin/get_yesterday_profit",
    gettotalprofit : "api/admin/get_month_profits",
    gettodayprofit : "api/admin/get_today_profits",
    getyesterdayprofit : "api/admin/get_yesterday_profits",
    gettotalbalance : "api/admin/admin_totalbalance",
    botstatus: "api/admin/bot_status",
    exchangestatus: "api/admin/exchange_status",
    getbotstatus: "api/admin/get_bot_status",
    getcoinmarket: "api/admin/get_market_details",
    apikeycreate: "api/admin/createKeymange",
    getkeys: "api/admin/getkeymanager",
    getOpenOrders: "api/profit/open_orders",
    getTradeHistory: "api/profit/trade_history",
    getProfit: "api/profit/bot_profit",
    getProfit_1: "api/profit/bot_profit_1",
    getProfit_2: "api/profit/bot_profit_2",
    getProfit_3: "api/profit/bot_profit_3",
    // getProfit: "api/profit/bot_profit_triangular",
    getchartData: "api/profit/chart_data",
    get_admin_balance: "api/admin/admin_balance",
    get_wallet_list: "api/admin/fund_wallet_list",
    get_admin_transactions: "api/admin/adminwallet_transactions_deposit",
    getmarkets: "api/admin/getmarkets",
    recent_profit: "api/admin/recent_profit",
    adminchangepswdlink : "api/admin/adminchangepswdlink",
    adminverifyForgotpasslink : "api/admin/verifyForgotpasslink",
    adminresetpassword : "api/admin/forgotpassword",
    get_recent_deposit:"api/admin/get_recent_deposit",
    getadminlogin:"api/admin/getadminLogindetails",
    getexchangestatus:"api/admin/getexchangestatus",
    getalladminlogin:"api/admin/getalladminlogin",
    getalltradehistory:"api/profit/getalltradehistory",
    getalltradehistory_1:"api/profit/getalltradehistory_1",
    getalltradehistory_2:"api/profit/getalltradehistory_2",
    getalltradehistory_3:"api/profit/getalltradehistory_3",
    // getalltradehistory:"api/profit/getalltradehistory_triangular",
    // getInvestment:"api/admin/get_investment",
    // updateInvestment:"api/admin/set_investment",
    exchangeBalance_1:"api/profit/exchange_balance_account1",
    exchangeBalance_2:"api/profit/exchange_balance_account2",
    exchangeBalance_3:"api/profit/exchange_balance_account3",
    exchangeBalance_4:"api/profit/exchange_balance_account4",
    get_profit_account1: "api/admin/get_profit_account1",
    get_profit_account2: "api/admin/get_profit_account2",
    get_profit_account3: "api/admin/get_profit_account3",
    get_profit_account4: "api/admin/get_profit_account4",
    getInvestment:"api/admin/get_investment_dca",
    updateInvestment:"api/admin/set_investment_dca",
}

export default service;