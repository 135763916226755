import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactSwitch from "react-switch";
import apiService from "../core/sevice/detail";
import {
  getMethod,
  postMethod,
  exchangegetMethod,
} from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { socket } from "../core/context/socket";

function InvestmentSettings() {

    const initialFormValue = {
        baseorder_limit: "",
        buy_level1_limit: "",
        buy_level2_limit: "",
        buy_level3_limit: "",
        buy_level4_limit: "",
        buy_level1_percentage: "",
        buy_level2_percentage: "",
        buy_level3_percentage: "",
        buy_level4_percentage: "",
        profit_percentage_sell:"",
        profit_percentage_total:""
      }

      const [validationnErr, setvalidationnErr] = useState("");
      const [formValue, setFormValue] = useState(initialFormValue);
      const [baseorder_limitValidate, setbaseorder_limitValidate,baseorder_limitValidateref] = useState(false);
      const [buy_level1_limitValidate, setbuy_level1_limitValidate,buy_level1_limitValidateref] = useState(false);
      const [buy_level2_limitValidate, setbuy_level2_limitValidate,buy_level2_limitValidateref] = useState(false);
      const [buy_level3_limitValidate, setbuy_level3_limitValidate,buy_level3_limitValidateref] = useState(false);
      const [buy_level4_limitValidate, setbuy_level4_limitValidate,buy_level4_limitValidateref] = useState(false);
      const [buy_level1_percentageValidate, setbuy_level1_percentageValidate,buy_level1_percentageValidateref] = useState(false);
      const [buy_level2_percentageValidate, setbuy_level2_percentageValidate,buy_level2_percentageValidateref] = useState(false);
      const [buy_level3_percentageValidate, setbuy_level3_percentageValidate,buy_level3_percentageValidateref] = useState(false);
      const [buy_level4_percentageValidate, setbuy_level4_percentageValidate,buy_level4_percentageValidateref] = useState(false);
      const [profit_percentage_sellValidate, setprofit_percentage_sellValidate,profit_percentage_sellValidateref] = useState(false);
      const [profit_percentage_totalValidate, setprofit_percentage_totalValidate,profit_percentage_totalValidateref] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [buttonLoader, setbuttonLoader] = useState(false);

      const {
        baseorder_limit,
        buy_level1_limit,
        buy_level2_limit,
        buy_level3_limit,
        buy_level4_limit,
        buy_level1_percentage,
        buy_level2_percentage,
        buy_level3_percentage,
        buy_level4_percentage,
        profit_percentage_sell,
        profit_percentage_total
      } = formValue;

      useEffect(() => {
        getInvestment();
      }, [0]);
  
      const handleChange = async (e) => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = {...formValue, ...{[name]: value}};
        setFormValue(formData);
        validate(formData);
      };

      const validate = async (values) => {
        const errors = {};
  
        if (!values.baseorder_limit) {
          errors.baseorder_limit = "Required field !";
          setbaseorder_limitValidate(true);
        } else {
          setbaseorder_limitValidate(false);
        }
  
        if (!values.buy_level1_limit) {
          errors.buy_level1_limit = "Required field !";
          setbuy_level1_limitValidate(true);
        } else {
          setbuy_level1_limitValidate(false);
        }

        if (!values.buy_level2_limit) {
          errors.buy_level2_limit = "Required field !";
          setbuy_level2_limitValidate(true);
        } else {
          setbuy_level2_limitValidate(false);
        }


        if (!values.buy_level3_limit) {
          errors.buy_level3_limit = "Required field !";
          setbuy_level3_limitValidate(true);
        } else {
          setbuy_level3_limitValidate(false);
        }


        if (!values.buy_level4_limit) {
          errors.buy_level4_limit = "Required field !";
          setbuy_level4_limitValidate(true);
        } else {
          setbuy_level4_limitValidate(false);
        }


        if (!values.buy_level1_percentage) {
          errors.buy_level1_percentage = "Required field !";
          setbuy_level1_percentageValidate(true);
        } else {
          setbuy_level1_percentageValidate(false);
        }

        if (!values.buy_level2_percentage) {
          errors.buy_level2_percentage = "Required field !";
          setbuy_level2_percentageValidate(true);
        } else {
          setbuy_level2_percentageValidate(false);
        }


        if (!values.buy_level3_percentage) {
          errors.buy_level3_percentage = "Required field !";
          setbuy_level3_percentageValidate(true);
        } else {
          setbuy_level3_percentageValidate(false);
        }


        if (!values.buy_level4_percentage) {
          errors.buy_level4_percentage = "Required field !";
          setbuy_level4_percentageValidate(true);
        } else {
          setbuy_level4_percentageValidate(false);
        }


        if (!values.profit_percentage_sell) {
          errors.profit_percentage_sell = "Required field !";
          setprofit_percentage_sellValidate(true);
        }
        else {
          setprofit_percentage_sellValidate(false);
        }
  
        if (!values.profit_percentage_total) {
          errors.profit_percentage_total = "Required field !";
          setprofit_percentage_totalValidate(true);
        }
        else {
          setprofit_percentage_totalValidate(false);
        }

        setvalidationnErr(errors);
        return errors;
      }

      const getInvestment = async () => {
        try {
          var data = {
            apiUrl: apiService.getInvestment,
          };
          setIsLoading(true);
          var resp = await getMethod(data);
          
          if (resp.status == true) {
            setIsLoading(false);
            formValue.baseorder_limit = resp.data.baseorder_limit;
            formValue.buy_level1_limit = resp.data.buy_level1_limit;
            formValue.buy_level2_limit = resp.data.buy_level2_limit;
            formValue.buy_level3_limit = resp.data.buy_level3_limit;
            formValue.buy_level4_limit = resp.data.buy_level4_limit;
            formValue.buy_level1_percentage = resp.data.buy_level1_percentage;
            formValue.buy_level2_percentage = resp.data.buy_level2_percentage;
            formValue.buy_level3_percentage = resp.data.buy_level3_percentage;
            formValue.buy_level4_percentage = resp.data.buy_level4_percentage;
            formValue.profit_percentage_sell = resp.data.profit_percentage_sell;
            formValue.profit_percentage_total = resp.data.profit_percentage_total;
          }
        } catch (error) {}
      };

      const formSubmit = async () => {
        validate(formValue);
        if (baseorder_limitValidateref.current === false && buy_level1_limitValidateref.current === false &&
            buy_level2_limitValidateref.current === false && buy_level2_limitValidateref.current === false &&
            buy_level3_limitValidateref.current === false && buy_level4_limitValidateref.current === false &&
            profit_percentage_sellValidateref.current === false && profit_percentage_totalValidateref.current === false
        ) {
          var data = {
            apiUrl: apiService.updateInvestment,
            payload: formValue,
          };
          setbuttonLoader(true);
          var resp = await postMethod(data);
            if(resp.status == true) {
                toast.success(resp.Message);
                getInvestment();
            }
            else {
                setbuttonLoader(false);
                toast.error(resp.Message);
            }
        }
      };

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container-fluid settings-container">
              {isLoading == true ? (
                <>
                  <div className="loader-css">
                    <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-lg-6">
                      <div className="setting_blw_col_title">Investment Settings</div>
                      <div className="setting_blw_col2">

                        <div className="input-settings">
                          <label>DCA Initial Order Limit</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="baseorder_limit"
                              value={baseorder_limit}
                              onChange={handleChange}
                              min = "0"
                            />
                            {baseorder_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.baseorder_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Level1 Order Limit</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level1_limit"
                              value={buy_level1_limit}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level1_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level1_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>


                        <div className="input-settings">
                          <label>DCA Level2 Order Limit</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level2_limit"
                              value={buy_level2_limit}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level2_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level2_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Level3 Order Limit</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level3_limit"
                              value={buy_level3_limit}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level3_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level3_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Level4 Order Limit</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level4_limit"
                              value={buy_level4_limit}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level4_limitValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level4_limit}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>


                        <div className="input-settings">
                          <label>DCA Level1 Buy Percentage</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level1_percentage"
                              value={buy_level1_percentage}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level1_percentageValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level1_percentage}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>


                        <div className="input-settings">
                          <label>DCA Level2 Buy Percentage</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level2_percentage"
                              value={buy_level2_percentage}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level2_percentageValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level2_percentage}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Level3 Buy Percentage</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level3_percentage"
                              value={buy_level3_percentage}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level3_percentageValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level3_percentage}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Level4 Buy Percentage</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="buy_level4_percentage"
                              value={buy_level4_percentage}
                              onChange={handleChange}
                              min = "0"
                            />
                            {buy_level4_percentageValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.buy_level4_percentage}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Sell Profit Percentage (Average Price)</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="profit_percentage_sell"
                              value={profit_percentage_sell}
                              onChange={handleChange}
                              min = "0"
                            />
                            {profit_percentage_sellValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.profit_percentage_sell}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="input-settings">
                          <label>DCA Sell Profit Percentage (Total)</label>
                          <input
                              type="number"
                              className="login-input mt-1"
                              name="profit_percentage_total"
                              value={profit_percentage_total}
                              onChange={handleChange}
                              min = "0"
                            />
                            {profit_percentage_totalValidate == true ? (
                                <p className="errorcss">
                                {" "}
                                {validationnErr.profit_percentage_total}{" "}
                                </p>
                            ) : (
                                ""
                            )}
                        </div>

                        <div>
                        
                        {buttonLoader == false ? (
                        <button className="login-submit-btn mt-4" onClick={formSubmit}>
                            <span className="login-submit">Submit</span>
                        </button>
                        ) : (
                        <button className="login-submit-btn mt-4">
                            <span className="login-submit">Loading</span>
                        </button>
                        )}
                    
                        
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
}

export default InvestmentSettings;
