import React, { useEffect } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactSwitch from "react-switch";
import apiService from "../core/sevice/detail";
import {
  getMethod,
  postMethod,
  exchangegetMethod,
} from "../core/sevice/common.api";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { socket } from "../core/context/socket";

function Opportunities() {
  const [opportunities, setopportunities, opportunitiesref] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {

  //     let socket_token = localStorage.getItem("socket_token");
  //     let socketsplit = socket_token.split("_");
  //     socket.connect();
  //     socket.emit("GetProfitAllData");
  //     socket.on("ProfitData", async (response) => {
  //         setIsLoader(true);
  //        console.log("profit data",response);
  //        if(response != null)
  //        {
  //         if(response.data.length > 0)
  //         {
  //             setopportunities(response.data);
  //         }
  //        }
  //     });
  // }, [0]);

  // useEffect(() => {
  //     return () => {
  //       socket.disconnect();
  //     };
  //   }, []);

  useEffect(() => {
    getBotStatus();
    getexchangeBalance();
  }, []);

  const [checkedBot, setCheckedBot] = useState(false);
  const [checkedBinance, setCheckedBinance] = useState(false);
  const [checkedBitget, setCheckedBitget] = useState(false);
  const [checkedBitmart, setCheckedBitmart] = useState(false);
  const [checkedBigone, setCheckedBigone] = useState(false);
  const [checkedBingx, setCheckedBingx] = useState(false);
  const [checkedBybit, setCheckedBybit] = useState(false);
  const [checkedDigifinex, setCheckedDigifinex] = useState(false);
  const [checkedGateio, setCheckedGateio] = useState(false);
  const [checkedHuobi, setCheckedHuobi] = useState(false);
  const [checkedKucoin, setCheckedKucoin] = useState(false);
  const [checkedMexc, setCheckedMexc] = useState(false);
  const [checkedOKX, setCheckedOKX] = useState(false);

  const [checkedIntra, setcheckedIntra] = useState(false);
  const [checkedDCA, setcheckedDCA] = useState(false);
  const [checkedArbitrage, setcheckedArbitrage] = useState(false);
  const [checkedTriangular, setcheckedTriangular] = useState(false);

  const handleChange = async (exchng, val) => {
    console.log(exchng, "exchng=-=-=-val", val);
    var obj = {
      exchange: exchng,
      status: val,
    };
    var data = {
      apiUrl: apiService.exchangestatus,
      payload: obj,
    };
    // setIsLoading(true);
    var resp = await postMethod(data);
    // setIsLoading(false);
    if (resp.status == true) {
      if (exchng === "Botstatus") {
        if (val == true) {
          setCheckedBot(true);
          toast.success(" Bot Status Activated ");
        } else {
          setCheckedBot(false);
          toast.error(" Bot Status Deactivated ");
        }
      }
      if (exchng === "Binance") {
        if (val == true) {
          setCheckedBinance(true);
          toast.success("Binance Activated ");
        } else {
          setCheckedBinance(false);
          toast.error("Binance Deactivated ");
        }
      }
      if (exchng === "Bitget") {
        if (val == true) {
          setCheckedBitget(true);
          toast.success("Bitget Activated ");
        } else {
          setCheckedBitget(false);
          toast.error("Bitget Deactivated ");
        }
      }
      if (exchng === "Bitmart") {
        if (val == true) {
          setCheckedBitmart(true);
          toast.success("Bitmart Activated ");
        } else {
          setCheckedBitmart(false);
          toast.error("Bitmart Deactivated ");
        }
      }
      if (exchng === "Bigone") {
        if (val == true) {
          setCheckedBigone(true);
          toast.success("Bigone Activated ");
        } else {
          setCheckedBigone(false);
          toast.error("Bigone Deactivated ");
        }
      }
      if (exchng === "Bingx") {
        if (val == true) {
          setCheckedBingx(true);
          toast.success("Bingx Activated ");
        } else {
          setCheckedBingx(false);
          toast.error("Bingx Deactivated ");
        }
      }
      if (exchng === "Bybit") {
        if (val == true) {
          setCheckedBybit(true);
          toast.success("Bybit Activated ");
        } else {
          setCheckedBybit(false);
          toast.error("Bybit Deactivated ");
        }
      }
      if (exchng === "Digifinex") {
        if (val == true) {
          setCheckedDigifinex(true);
          toast.success("Digifinex Activated ");
        } else {
          setCheckedDigifinex(false);
          toast.error("Digifinex Deactivated ");
        }
      }
      if (exchng === "Gateio") {
        if (val == true) {
          setCheckedGateio(true);
          toast.success("Gate.io Activated ");
        } else {
          setCheckedGateio(false);
          toast.error("Gate.io Deactivated ");
        }
      }
      if (exchng === "Huobi") {
        if (val == true) {
          setCheckedHuobi(true);
          toast.success("Huobi Activated ");
        } else {
          setCheckedHuobi(false);
          toast.error("Huobi Deactivated ");
        }
      }
      if (exchng === "Kucoin") {
        if (val == true) {
          setCheckedKucoin(true);
          toast.success("Kucoin Activated ");
        } else {
          setCheckedKucoin(false);
          toast.error("Kucoin Deactivated ");
        }
      }
      if (exchng === "Mexc") {
        if (val == true) {
          setCheckedMexc(true);
          toast.success("Mexc Activated ");
        } else {
          setCheckedMexc(false);
          toast.error("Mexc Deactivated ");
        }
      }
      if (exchng === "OKX") {
        if (val == true) {
          setCheckedOKX(true);
          toast.success("OKX Activated ");
        } else {
          setCheckedOKX(false);
          toast.error("OKX Deactivated ");
        }
      }
      if (exchng === "DCA") {
        if (val == true) {
          setcheckedDCA(true);
          toast.success(" DCA Strategy Status Activated ");
        } else {
          setcheckedDCA(false);
          toast.error(" DCA Strategy Status Deactivated ");
        }
      }

      if (exchng === "Intra") {
        if (val == true) {
          setcheckedIntra(true);
          toast.success(" Intra Strategy Status Activated ");
        } else {
          setcheckedIntra(false);
          toast.error(" Intra Strategy Status Deactivated ");
        }
      }

      if (exchng === "Triangular") {
        if (val == true) {
          setcheckedTriangular(true);
          toast.success(" Triangular Strategy Status Activated ");
        } else {
          setcheckedTriangular(false);
          toast.error(" Triangular Strategy Status Deactivated ");
        }
      }

      if (exchng === "Arbitrage") {
        if (val == true) {
          setcheckedArbitrage(true);
          toast.success(" Arbitrage Strategy Status Activated ");
        } else {
          setcheckedArbitrage(false);
          toast.error(" Arbitrage Strategy Status Deactivated ");
        }
      }
    }
  };

  const getBotStatus = async () => {
    try {
      var data = {
        apiUrl: apiService.getexchangestatus,
      };
      setIsLoading(true);
      var resp = await getMethod(data);
      setIsLoading(false);
      if (resp.status == true) {
        console.log(resp.data, "Bot status datas");
        console.log(resp.data[0].Binance, "resp.data.Binance");
        setCheckedBot(resp.data[0].Botstatus);
        setCheckedBinance(resp.data[0].Binance);
        setCheckedBitget(resp.data[0].Bitget);
        setCheckedBitmart(resp.data[0].Bitmart);
        setCheckedBigone(resp.data[0].Bigone);
        setCheckedBingx(resp.data[0].Bingx);
        setCheckedBybit(resp.data[0].Bybit);
        setCheckedDigifinex(resp.data[0].Digifinex);
        setCheckedGateio(resp.data[0].Gateio);
        setCheckedHuobi(resp.data[0].Huobi);
        setCheckedKucoin(resp.data[0].Kucoin);
        setCheckedMexc(resp.data[0].Mexc);
        setCheckedOKX(resp.data[0].OKX);
        setcheckedDCA(resp.data[0].DCA);
        setcheckedIntra(resp.data[0].Intra);
        setcheckedTriangular(resp.data[0].Triangular);
        setcheckedArbitrage(resp.data[0].Arbitrage);
      }
    } catch (error) {}
  };

  // const handleBot = (value) => {
  //     if ( value == true) {
  //       setChecked(true);
  //       setIsActive(true);
  //       toast.success("Bot Is Activated")
  //     } else {
  //       setChecked(false);
  //       setIsActive(false);
  //       toast.error("Bot Is Deactivated")
  //     }
  //   }

  const getexchangeBalance = async () => {
    try {
      var data = {
        apiUrl: apiService.exchangeBalance,
      };
      setIsLoading(true);
      var resp = await exchangegetMethod(data);
      setIsLoading(false);
      if (resp.status == true) {
        console.log(resp, "exchange Balance");
      }
    } catch (error) {}
  };

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-sm-3">
            <Sidebar />
          </div>
          <div className="col-lg-10 col-sm-9">
            <div className="container-fluid settings-container">
              {isLoading == true ? (
                <>
                  <div className="loader-css">
                    <img
                      src={require("../images/Dual Ball@1x-1.0s-200px-200px.gif")}
                      alt="loader"
                      className="loader-gif"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-lg-10">
                      <div className="setting_blw_col_title">Trade Bot</div>
                      <div className="setting_blw_col2">
                        <div className="settings_inner_box">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Bot_status.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">
                              Bot Status
                            </span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBot == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBot == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                // checked={checked}
                                // onChange={handleChange}
                                checked={checkedBot}
                                onChange={(val) =>
                                  handleChange("Botstatus", val)
                                }
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBot ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="col-lg-10">
                      <div className="setting_blw_col_title">
                        Exchanges Settings
                      </div>
                      <div className="setting_blw_col">
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Binance.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Binance</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBinance == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBinance == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedBinance}
                                onChange={(val) => handleChange("Binance", val)}
                                onColor="rgba(255, 67, 67, 0.3)"
                                offColor="rgba(255, 67, 67, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBinance ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Bidget.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Bitget</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBitget == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBitget == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedBitget}
                                onChange={(val) => handleChange("Bitget", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBitget ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Bitmart.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Bitmart</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBitmart == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBitmart == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedBitmart}
                                onChange={(val) => handleChange("Bitmart", val)}
                                onColor="rgba(255, 67, 67, 0.3)"
                                offColor="rgba(255, 67, 67, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBitmart ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Bigone.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Bigone</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBigone == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBigone == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedBigone}
                                onChange={(val) => handleChange("Bigone", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBigone ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Bingx.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Bingx</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBingx == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBingx == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedBingx}
                                onChange={(val) => handleChange("Bingx", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBingx ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Bybit.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Bybit</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBybit == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedBybit == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedBybit}
                                onChange={(val) => handleChange("Bybit", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedBybit ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Digitinex.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">
                              Digifinex
                            </span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedDigifinex == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedDigifinex == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedDigifinex}
                                onChange={(val) =>
                                  handleChange("Digifinex", val)
                                }
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedDigifinex ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Gateio.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Gate.io</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedGateio == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedGateio == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedGateio}
                                onChange={(val) => handleChange("Gateio", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedGateio ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Haubi.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Huobi</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedHuobi == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedHuobi == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedHuobi}
                                onChange={(val) => handleChange("Huobi", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedHuobi ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Kucoin.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Kucoin</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedKucoin == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedKucoin == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedKucoin}
                                onChange={(val) => handleChange("Kucoin", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedKucoin ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/Mexc.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">Mexc</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedMexc == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedMexc == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedMexc}
                                onChange={(val) => handleChange("Mexc", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedMexc ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings_inner_box mt-4">
                          <div className="profit_top_rght">
                            <img
                              src={require("../images/connect exchange/OKX.png")}
                              alt="bot status"
                              className="setting_in_img"
                            />
                            <span className="dashboard-portfolio">OKX</span>
                          </div>
                          <div className="setting_in_right">
                            <div className="setting_in_rightfrst">
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedOKX == true
                                      ? "bot-active-headleft"
                                      : "order-from-fil"
                                  }
                                >
                                  Active
                                </span>
                              </div>
                              <div className="setting_in_active">
                                <span
                                  className={
                                    checkedOKX == true
                                      ? "order-from-fil"
                                      : "bot-deactive-headleft"
                                  }
                                >
                                  Inactive
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <ReactSwitch
                                checked={checkedOKX}
                                onChange={(val) => handleChange("OKX", val)}
                                onColor="rgba(45, 226, 109, 0.3)"
                                offColor="rgba(45, 226, 109, 0.3)"
                                height={24}
                                width={40}
                                handleDiameter={14}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                className={`custom-switch ${
                                  checkedOKX ? "switch-on" : "switch-off"
                                }`}
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

    
    </div>
  );
}

export default Opportunities;
